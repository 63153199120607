/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Story Detailed Viewed 
Brief :
StoryDetailView, retrieves detailed story data based on the provided story ID from an API using fetchStoryData.
It utilizes React Router to get the story ID from the URL parameters. Once the data is fetched, it passes it to the DetailedView component for rendering the detailed story view.
This component also includes Header and Footer components to encapsulate the entire view of the story.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { fetchStoryData } from '../../../../../../Controller/WS-WrittenStories/StoryViewController'; // Import the API function
import '../../CS/StoryDetailView.css'
import DetailedView from './DetailedView';
import LoadingGif from '../../../Assets/Images/Loading.WebM'
import fetchAuthorProfilePublicationsData from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfilePublications';

function StoryDetailView() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const { story_id } = useParams();
    // const { story_id } = location.state;

    const [data, setData] = useState({});
    const [authorId, setAuthorId] = useState(null); // State to hold the authorId

    useEffect(() => {
        async function fetchData() {
            try {
                // Set loading to true before fetching data
                setLoading(true);

                // Fetch the story data
                const result = await fetchStoryData(story_id, userId);

                // Set the data returned from the API
                setData(result);

                // Extract authorId from the result (assuming it's available in the result object)
                const authorIdFromData = result.authorUserID;
                console.log(authorIdFromData)
                setAuthorId(authorIdFromData);

                // Set loading to false after data is fetched
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Set loading to false in case of an error
                setLoading(false);
            }
        }

        fetchData();
    }, [story_id, userId]); // Include userId in the dependency array if it's used inside fetchStoryData
    const [page, setPage] = useState(1);
    const [totalStories, setTotalStories] = useState(0);
    const [authorProfilePublicationsData, setAuthorProfilePublicationsData] = useState([]);
    useEffect(() => {
        // Only call the second API if authorId is available
        if (authorId) {
            fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfilePublicationsData, "all_stories");
        }
    }, [authorId, page]); // Fetch publications whenever authorId or page changes
    return (
        <div>
            <Header authToken={(location.state) ? location.state.authToken : null} />
            <div>
                <DetailedView data={data} loading={loading}
                    fetchAuthor={fetchAuthorProfilePublicationsData}
                    AuthorData={authorProfilePublicationsData}
                />
            </div>
            <Footer />
        </div>
    )
}

export default StoryDetailView
