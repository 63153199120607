/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Home Screen Stories
Brief : 
Purpose: Represents the Home Screen Stories section of the "wikibedtimestories.com" website.
Functionality:
Rendering Stories: Displays stories along with their images, titles, authors, creation dates, views, ratings, and summaries.
Pagination: Implements navigation buttons to move between pages of stories.
Category Handling: Allows users to filter stories by categories and subcategories.
Sharing and Navigation: Includes functionality for sharing stories and navigating to specific story details.
Component Structure: Contains various components for handling story content, categories, and pagination.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../CS/Stories.css'
import DownArrow from '../../../Assets/Images/Arrow - Down.webp'
import StaticCat from '../../../Assets/Images/Category image.webp'
import SortByCat from '../../../Assets/Images/SortByCat.webp'
import Heart from '../../../Assets/Images/Heart.webp'
import HeartFilled from '../../../Assets/Images/HeartFilled.webp'
import Share from '../../../Assets/Images/Share.webp'
import Bookmark from '../../../Assets/Images/Bookmark.webp'
import BookmarkFilled from '../../../Assets/Images/BookmarkFilled.webp'
import RightArrow from '../../../Assets/Images/ArrowRight.webp'
import NonSelect from '../../../Assets/Images/NonSelect.webp'
import Select from '../../../Assets/Images/Select.webp'
import ArrowDown1 from '../../../Assets/Images/Arrow - Down.webp'
// import ArrowDown1 from '../../../Assets/Images/Arrow - Down 2.webp'
import ArrowDown from '../../../Assets/Images/Arrow - Down 2.webp'
import ArrowUp1 from '../../../Assets/Images/Arrow - Up.webp'
// import ArrowUp1 from '../../../Assets/Images/Arrow - Up 2.WebP'
import ArrowUp from '../../../Assets/Images/Arrow - Up 2.WebP'
import LoadingImage from '../../../Assets/Images/LoadingImage.webp'
import LoadingGif from '../../../Assets/Images/Loading.WebM'
import AdsListView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsListView';
import CatNumb from '../../../Assets/Images/CatNumb.webp'
import NewTag from '../../../Assets/Images/NewTag.webp'
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, airecommended, update_story_views } from '../../../../../../Model/WS-WrittenStories/WrittenStories';
import Navigation from '../Header/Navigation';
import AgeCategory from './AgeCategory';
import ArrowLeft from '../../../Assets/Images/ArrowLeftPagination.webp'
import ArrowRight from '../../../Assets/Images/ArrowRightPagination.webp'

function Stories({ data, allStoriesLoaded,
    loading,
    setSortBy, setPage, page, setData, setAllStoriesLoaded, totalStories,
    totalStoriesObject,
    firstAndLastStoriesNumber,
    apiCallInProgress, totalPages, apiCallCompleted, setApiCallCompleted, selectedAge
    , setSelectedAge }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [UserID, setUserID] = useState(localStorage.getItem('UserID') || null);
    const CurrentDate = new Date(new Date().toUTCString());
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const goToNextPage = () => {
        if (!allStoriesLoaded) {
            setPage(page + 1);
            setData([]);
        }
    };



    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPages = 8; // Set your maximum number of pages
        for (let i = 1; i <= Math.min(totalPages, maxPages); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-number ${i === page ? 'active' : ''}`}
                    onClick={() => {
                        setPage(i);
                        setData([]);
                        window.scrollTo(0, 0); // Clear previous data
                        navigate(`/?page=${i}`);
                    }}
                >
                    {i} {/* Display the page number directly */}
                </button>
            );
        }
        return pageNumbers;
    };

    const goToFirstPage = () => {
        if (page > 1) {
            setPage(1);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=1`);
        }
    };

    const goToLastPage = () => {
        if (page < totalPages) {
            setPage(totalPages);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=${totalPages}`);
        }
    };

    const goToPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=${page - 1}`);
        }
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // console.log('logged in')
        }
    };

    const [heartedStories, setHeartedStories] = useState(new Array(data.length).fill(false));

    // Function to handle heart click for a specific story
    const handleShowHeart = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the heartedStories array
            const updatedHeartedStories = [...heartedStories];

            // Toggle the hearted state for the specific story
            updatedHeartedStories[index] = !updatedHeartedStories[index];

            // Update the hearted state for all stories
            setHeartedStories(updatedHeartedStories);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(data.length).fill(false));

    // Function to handle bookmark click for a specific story
    const handleShowBookmark = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedBookmarkedStories = [...bookmarkedStories];

            // Toggle the bookmarked state for the specific story
            updatedBookmarkedStories[index] = !updatedBookmarkedStories[index];

            // Update the bookmarked state for all stories
            setBookmarkedStories(updatedBookmarkedStories);

        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const ai = async (storyID) => {
        const url = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/ai/story_status`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': authToken,

        };
        storyID = String(storyID);
        const body = JSON.stringify({
            "story_id": storyID,
            "story_type": "read",
            "time": "10"
        });

        try {
            const response = await fetch(airecommended, {
                method: 'PUT',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    const recommendStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
            story_type: "read",
            time: "10",
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(airecommended, requestBody, { headers: requestHeaders })
            .then((response) => { })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleStoryClick = (storyID) => {
        // console.log(storyID)
        updateStory(storyID);
        ai(storyID)
    }
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                navigateToStory();
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    const navigateToStory = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${storyID}/${formattedTitle}`, {
            state: { story_id: storyID },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const handleDetailView = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${storyID}/${formattedTitle}`, {
            state: { story_id: storyID },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const [isOpen, setIsOpen] = useState(false);
    const [arrowImage, setArrowImage] = useState(ArrowDown);
    const toggleCategoryList = () => {
        setSortByListVisible(false);
        setSubcategories([]);
        setIsCategoryListVisible(!isCategoryListVisible);
        setIsOpen(!isOpen);
        setArrowImage(isOpen ? ArrowDown : ArrowUp);
    };

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);

    const mainCategories = [
        { id: 3, name: 'Epic' },
        { id: 2, name: 'Folktales' },
        { id: 1, name: 'Mythology' },
        { id: 99, name: 'Region' },
        // Add more main categories as needed
    ];
    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/sub_categories?pageNumber=1&sortMode=ASC&sortBy=id&pageNumber=1&pageSize=20&primaryCategoryId=${categoryId}`);
            const data = await response.json();
            if (data && data.data) {
                setSubcategories(data.data);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };
    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);
    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        fetchSubcategories(categoryId);
        setIsOpen(true);
    };
    const handleSortByClick = () => {
        setIsOpen(true);

    };
    const [isSortByListVisible, setSortByListVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Recently Released');
    const [containerClassName, setContainerClassName] = useState('ws-stories-catsortby-text');
    const [arrowImage1, setArrowImage1] = useState(ArrowDown1);
    const toggleSortByList = () => {
        setIsOpen(false);
        setSortByListVisible(!isSortByListVisible);
        setContainerClassName(isSortByListVisible ? 'selected-sortby' : 'other-sortby-name');
        setArrowImage1(isSortByListVisible ? ArrowDown1 : ArrowUp1);

    };
    const [divIdToRender, setDivIdToRender] = useState('divTwo'); // Initialize with an empty string or your default value

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSortByListVisible(false);
        setContainerClassName('selected-sortby');
        setArrowImage1(ArrowDown1)
        setData([]);
        setPage(1);
        // Determine the sorting parameter based on the selected option
        let divIdToRender;
        let sortingParameter;
        switch (option) {
            case 'Recommended':
                sortingParameter = 'recommended';

                setDivIdToRender('divOne');
                break;
            case 'Praiseworthy':
                sortingParameter = 'rating';
                setDivIdToRender('divTwo');
                break;
            case 'Recently Released':
                sortingParameter = 'last_approved_date';
                setDivIdToRender('divTwo');
                break;
            case 'Popularly Viewed':
                sortingParameter = 'views';
                setDivIdToRender('divTwo');
                break;
            default:
                sortingParameter = 'default_parameter';
                setDivIdToRender('divTwo');
        }
        setSortBy(sortingParameter);
        setTimeout(() => {

        }, 1000); // Adjust the timeout value if needed
    };
    const getImgSrc = (option) => {
        return selectedOption === option ? Select : NonSelect;

    };


    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }
    const authorview = (authorId) => {
        navigate(`/authorprofile/${authorId}`);
        window.scrollTo(0, 0);
    }
    const categoryBoxRef = useRef(null);

    const sortByBoxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (categoryBoxRef.current && !categoryBoxRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCategoryListVisible(false);
            setArrowImage(ArrowDown);
        }
        if (sortByBoxRef.current && !sortByBoxRef.current.contains(event.target)) {
            setSortByListVisible(false);
            setContainerClassName('other-sortby-name');
            setArrowImage1(ArrowDown1);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    function formatDateMob(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    return (
        <div>
            <div className='ws-stories' >
                <div className='ws-stories-basket'>
                    <div className='web'>


                        <div className='ws-stories-btn' >
                            <div className='ws-stories-corner-left' >
                                <div className='ws-stories-category-cat'
                                    onClick={toggleCategoryList}
                                >
                                    <img src={StaticCat} alt='category' />
                                </div>
                                <div
                                    className={isCategoryListVisible ? 'other-class-name' : 'ws-stories-category-text'}

                                    onClick={toggleCategoryList}>
                                    Categories
                                    <img src={arrowImage} alt='ArrowDown' />
                                </div>
                                {isOpen && (
                                    <div className='category-list'
                                    //  ref={categoryBoxRef}
                                    >
                                        {/* Display static main categories */}
                                        {mainCategories.map((category) => (
                                            <div className='category-item' key={category.id} onClick={() => handleCategoryClick(category.id)} >
                                                {category.name}
                                            </div>
                                        ))}
                                        {/* Conditionally display fetched subcategories */}
                                        {selectedCategory !== null && subcategories.length > 0 && (
                                            <div className='subcategory-list'>
                                                {subcategories.map((subcategory) => (
                                                    <div className='subcategory-item' key={subcategory.subCategoryID}
                                                        onClick={() => {
                                                            navigate(`/category/${selectedCategory}/subcategory/${subcategory.subCategoryID}/${subcategory.primaryCategoryName}/${subcategory.subCategoryName}`, {
                                                            });
                                                        }} >
                                                        {subcategory.subCategoryName}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {/* Show nothing (empty div) when no subcategories available */}
                                        {selectedCategory !== null && subcategories.length === 0 && <div></div>}
                                    </div>
                                )}
                            </div>


                            <div className='ws-stories-corner-right' >
                                <div className='ws-stories-catsortby'
                                >
                                    <img src={SortByCat} alt='sortby' />
                                </div>
                                <div
                                    className={containerClassName}
                                    onClick={toggleSortByList}>
                                    {/* Sort By */}
                                    {selectedOption}
                                    <div className='sortbyarrow'>
                                        <img src={arrowImage1} alt='ArrowDown' />
                                    </div>

                                </div>
                                {isSortByListVisible && (
                                    <div className='dropdown-options'
                                    // ref={sortByBoxRef}
                                    >
                                        {(authToken == null || authToken === "no token") ?
                                            <div onClick={handleLoginSignUp}
                                            >

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                            :
                                            <div onClick={() => handleOptionClick('Recommended')}>

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                        }
                                        <div onClick={() => handleOptionClick('Recently Released')}>
                                            <img src={getImgSrc('Recently Released')} alt='select' />

                                            Recently released</div>
                                        <div onClick={() => handleOptionClick('Praiseworthy')}>
                                            <img src={getImgSrc('Praiseworthy')} alt='select' />
                                            Praiseworthy</div>
                                        <div onClick={() => handleOptionClick('Popularly Viewed')}>
                                            <img src={getImgSrc('Popularly Viewed')} alt='select' />
                                            Popularly viewed</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <AgeCategory selectedAge={selectedAge}
                            setSelectedAge={setSelectedAge} />
                    </div>
                    <div className='mobile'>
                        <div className='ws-stories-btn'>
                            <div className='ws-stories-corner-left' >
                                <button className='ws-stories-category' onClick={toggleCategoryList}>Categories
                                    <img src={DownArrow} alt='down' />
                                </button>
                            </div>
                            {isOpen && (
                                <div className='category-list' >
                                    {/* Display static main categories */}
                                    {mainCategories.map((category) => (
                                        <div className='category-item' key={category.id} onClick={() => handleCategoryClick(category.id)}>
                                            {category.name}
                                        </div>
                                    ))}
                                    {/* Conditionally display fetched subcategories */}
                                    {selectedCategory !== null && subcategories.length > 0 && (
                                        <div className='subcategory-list'>
                                            {subcategories.map((subcategory) => (
                                                <div className='subcategory-item' key={subcategory.subCategoryID}
                                                    onClick={() => {
                                                        navigate(`/category/${selectedCategory}/subcategory/${subcategory.subCategoryID}/${subcategory.primaryCategoryName}/${subcategory.subCategoryName}`, {
                                                            state: { authToken: authToken }
                                                        });
                                                    }} >
                                                    {subcategory.subCategoryName}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {/* Show nothing (empty div) when no subcategories available */}
                                    {selectedCategory !== null && subcategories.length === 0 && <div></div>}
                                </div>
                            )}
                            <div className='ws-stories-corner-right' >
                                <button className='ws-stories-sortby'
                                    onClick={toggleSortByList}
                                >Sort By
                                    <img src={DownArrow} alt='down' />
                                </button>
                                {isSortByListVisible && (
                                    <div className='dropdown-options'>
                                        {(authToken == null || authToken === "no token") ?
                                            <div onClick={handleLoginSignUp}
                                            >

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                            :
                                            <div onClick={() => handleOptionClick('Recommended')}>

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                        }

                                        <div onClick={() => handleOptionClick('Recently Released')}>
                                            <img src={getImgSrc('Recently Released')} alt='select' />

                                            Recently released</div>
                                        <div onClick={() => handleOptionClick('Praiseworthy')}>
                                            <img src={getImgSrc('Praiseworthy')} alt='select' />
                                            Praiseworthy</div>
                                        <div onClick={() => handleOptionClick('Popularly Viewed')}>
                                            <img src={getImgSrc('Popularly Viewed')} alt='select' />
                                            Popularly viewed</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Navigation />
                        <AgeCategory selectedAge={selectedAge}
                            setSelectedAge={setSelectedAge} />
                    </div>
                    {/* other sortby designed */}
                    {divIdToRender === 'divTwo' &&
                        <div className='ws-stories-box-align-bottom' id="divTwo" >
                            {loading ? ( // Conditionally render the loading screen or data

                                <div className='loading'>
                                    <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                                </div>
                            ) : (
                                data.map((story, i) => {
                                    const date = new Date(story.creationDate)
                                    const currentFirstday = new Date(new Date().setDate(new Date().getDate() - 1))
                                    const currentSecondday = new Date(new Date().setDate(new Date().getDate() - 2))
                                    const currentThirdday = new Date(new Date().setDate(new Date().getDate() - 3))
                                    const currentForthday = new Date(new Date().setDate(new Date().getDate() - 4))
                                    if (
                                        CurrentDate.toDateString() === date.toDateString() ||
                                        currentFirstday.toDateString() === date.toDateString()
                                        ||
                                        currentSecondday.toDateString() === date.toDateString()
                                        ||
                                        currentThirdday.toDateString() === date.toDateString()
                                        ||
                                        currentForthday.toDateString() === date.toDateString()
                                    ) {
                                        return (
                                            <div key={i}>
                                                <div className='web'>

                                                    <div className='ws-stories-box' key={i}
                                                        onClick={() => handleStoryClick(story.storyID)}
                                                    >
                                                        <div className='ws-stories-img'
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' loading="eager" />
                                                        </div>
                                                        <div className='ws-stories-text'>
                                                            <div className='ws-stories-title-img' >
                                                                <div className='ws-stories-box-title'
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                >

                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb-new' >
                                                                    <div className='ws-new-tag'>
                                                                        <img src={NewTag} alt='new-tag' />
                                                                    </div>
                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }


                                                                    </div>

                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }
                                                                    </div>



                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date'>
                                                                <div className='ws-stories-box-author'
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)} >By {story.authorName}&nbsp;-&nbsp;</div>
                                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                            </div>
                                                            <div
                                                                className="ws-stories-box-wrapper"
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                style={{ padding: '45px 0', cursor: 'pointer' }}
                                                            >
                                                                <div className="ws-stories-box-summary">
                                                                    {story.summary}
                                                                </div>
                                                            </div>
                                                            <div className='ws-story-box-viewrate'
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='mobile'>
                                                    <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                        <div className='ws-stories-img' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img fetchpriority="high" src={story.storyThumbnailImage} alt='thumbnail' />
                                                        </div>
                                                        <div className='ws-stories-text' >
                                                            <div className='ws-stories-title-img'>
                                                                <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb'>

                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }


                                                                    </div>
                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                            </div>
                                                            <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                {story.summary}
                                                            </div>
                                                            <div className='ws-stories-box-view-rate'
                                                            >
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-ratebox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewboxtime'>
                                                                    <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                    <div className='ws-story-ratetext'>mins</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }
                                    return (
                                        <div key={i}>
                                            <div className='web'>

                                                <div className='ws-stories-box' key={i}
                                                    onClick={() => handleStoryClick(story.storyID)}
                                                >
                                                    <div className='ws-stories-img'
                                                        onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                    >
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                    </div>
                                                    <div className='ws-stories-text'>
                                                        <div className='ws-stories-title-img' >
                                                            <div className='ws-stories-box-title'
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                            >

                                                                {story.title}
                                                            </div>
                                                            <div className='ws-stories-img-hsb' >

                                                                <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                    {
                                                                        story.favouriteStory ? (
                                                                            heartedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />

                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            heartedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }


                                                                </div>
                                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                    {
                                                                        story.is_saved ? (
                                                                            bookmarkedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />



                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            bookmarkedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }
                                                                </div>



                                                            </div>
                                                            {showPopup && (
                                                                <div className="pop-up-overlay">
                                                                    <div className="pop-up-box">
                                                                        <p>Login first</p>
                                                                        <button onClick={handleClosePopup}>Close</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='ws-stories-box-auth-date'
                                                        >
                                                            <div className='ws-stories-box-author'
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                            <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                        </div>
                                                        <div
                                                            className="ws-stories-box-wrapper"
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                            style={{ padding: '45px 0', cursor: 'pointer' }}
                                                        >
                                                            <div className="ws-stories-box-summary">
                                                                {story.summary}
                                                            </div>
                                                        </div>


                                                        <div className='ws-story-box-viewrate'
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                        >
                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='ws-story-view'>{story.views}</div>
                                                                <div className='ws-story-viewtext'>views</div>
                                                            </div>
                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                <div className='ws-story-ratetext'>rating</div>
                                                            </div>
                                                            <div className='ws-stories-box-viewboxtime'>
                                                                <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                <div className='ws-story-ratetext'>mins</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='mobile'>
                                                <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                    <div className='ws-stories-img' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                    >
                                                        <img fetchpriority="high" src={story.storyThumbnailImage} alt='thumbnail' />
                                                    </div>
                                                    <div className='ws-stories-text' >
                                                        <div className='ws-stories-title-img'>
                                                            <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                {story.title}
                                                            </div>
                                                            <div className='ws-stories-img-hsb'>

                                                                <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                    {
                                                                        story.favouriteStory ? (
                                                                            heartedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />

                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            heartedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }


                                                                </div>

                                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                    {
                                                                        story.is_saved ? (
                                                                            bookmarkedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />



                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            bookmarkedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }
                                                                </div>

                                                            </div>
                                                            {showPopup && (
                                                                <div className="pop-up-overlay">
                                                                    <div className="pop-up-box">
                                                                        <p>Login first</p>
                                                                        <button onClick={handleClosePopup}>Close</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                            <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                        </div>
                                                        <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            {story.summary}
                                                        </div>
                                                        <div className='ws-stories-box-view-rate'
                                                        >
                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='ws-story-view'>{story.views}</div>
                                                                <div className='ws-story-viewtext'>views</div>
                                                            </div>
                                                            <div className='ws-stories-box-ratebox'>
                                                                <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                <div className='ws-story-ratetext'>rating</div>
                                                            </div>
                                                            <div className='ws-stories-box-viewboxtime'>
                                                                <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                <div className='ws-story-ratetext'>mins</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            )}
                        </div>}

                    {/* recommendation designed */}
                    {divIdToRender === 'divOne' &&
                        <div className='ws-stories-box-align-bottom' id="divTwo" >
                            {loading ? ( // Conditionally render the loading screen or data
                                <div className='loading'>
                                    <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                                </div>
                            ) : apiCallCompleted && data.length === 0 ? (
                                <div className='ws-stories-storiescount'>No stories found. See more stories to get recommended Stories.</div>
                            ) : (
                                data.map((story, i) => {
                                    return (
                                        <div key={i}>
                                            <div className='ws-stories-storiescount'>Because you read stories like “{story.input_story_title}”</div>
                                            {story.recommendations.map((recommendation, index) => (
                                                <div key={index}>
                                                    <div className='web'>

                                                        <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(recommendation.story_id)}
                                                        >
                                                            <div className='ws-stories-img'
                                                                onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                            >
                                                                <img src={recommendation.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text'>
                                                                <div className='ws-stories-title-img' >
                                                                    <div className='ws-stories-box-title'
                                                                        onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                    >{recommendation.title}

                                                                    </div>
                                                                    <div className='ws-stories-img-hsb' >
                                                                        <div className='ws-heart' onClick={() => handleShowHeart(recommendation.story_id, i)}>
                                                                            {
                                                                                recommendation.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(recommendation.story_id, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(recommendation.story_id, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(recommendation.story_id, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date'
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {recommendation.authorName}

                                                                        &nbsp;-&nbsp;</div>
                                                                    <div className='ws-stories-box-date'>{formatDate(recommendation.creationDate)}</div>
                                                                </div>

                                                                <div
                                                                    className="ws-stories-box-wrapper"
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                    style={{ padding: '45px 0', cursor: 'pointer' }}
                                                                >
                                                                    <div className="ws-stories-box-summary">
                                                                        {recommendation.summary}
                                                                    </div>
                                                                </div>
                                                                <div className='ws-story-box-viewrate'
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{recommendation.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-rate'>{Number(recommendation.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='mobile'>
                                                        <div className='ws-stories-box' onClick={() => handleStoryClick(recommendation.story_id)}>
                                                            <div className='ws-stories-img' onClick={navigateToStory.bind(null, recommendation.storyID, recommendation.title)}
                                                            >
                                                                <img fetchpriority="high" src={recommendation.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text' >
                                                                <div className='ws-stories-title-img'>
                                                                    <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                    >
                                                                        {recommendation.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb'>
                                                                        <div className='ws-heart' onClick={() => handleShowHeart(recommendation.story_id, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>

                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(recommendation.story_id, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(recommendation.story_id, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(recommendation.story_id, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {recommendation.authorName} - </div>
                                                                    <div className='ws-stories-box-date'>{formatDate(recommendation.creationDate)}</div>
                                                                </div>
                                                                <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    {recommendation.summary}
                                                                </div>
                                                                <div className='ws-stories-box-view-rate'
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{recommendation.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-ratebox'>
                                                                        <div className='ws-story-rate'>{Number(recommendation.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )

                                })
                            )}
                        </div>}
                </div>
                <div className='ws-stories-ads'>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                </div>
            </div>

            <div className='ws-stories-pagination'>

            </div>
            {divIdToRender === 'divTwo' &&
                <div className="pagination" id='divTwo'>
                    <div className="page-count">
                        {page} of {totalPages}
                    </div>
                    <div className="pagination-controls">
                        <div className="pagination-center">
                            <div className="page-numbers">
                                <div className='web'>
                                    <button className="first-page-button" onClick={goToFirstPage}>
                                        Go to First
                                    </button>
                                </div>
                                <button className="prev-page-button" onClick={goToPrevPage}>
                                    <img src={ArrowLeft} />
                                </button>
                                {renderPageNumbers()}
                                {!allStoriesLoaded && page < totalPages && (
                                    <>
                                        <><button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}><img src={ArrowRight} /></button></>
                                        {/* <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>

                                        </button> */}
                                    </>


                                )}
                                <div className='web'>
                                    <button className="last-page-button" onClick={goToLastPage}>
                                        Go to Last
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {divIdToRender === 'divOne' &&
                <div id='divOne'>
                    <div className="pagination" id='divTwo'>
                        <div className="page-count">
                            {page} of {totalPages}
                        </div>
                        <div className="pagination-controls">
                            <div className="pagination-center">
                                <div className="page-numbers">
                                    <div className='web'>
                                        <button className="first-page-button" onClick={goToFirstPage}>
                                            Go to First
                                        </button>
                                    </div>
                                    <button className="prev-page-button" onClick={goToPrevPage}>
                                        <img src={ArrowLeft} />
                                    </button>
                                    {renderPageNumbers()}
                                    {!allStoriesLoaded && page < totalPages && (
                                        <>
                                            <><button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}><img src={ArrowRight} /></button></>
                                            {/* <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>

                                        </button> */}
                                        </>


                                    )}
                                    <div className='web'>
                                        <button className="last-page-button" onClick={goToLastPage}>
                                            Go to Last
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="ws-stories-pagination-box">

            </div>

        </div >
    )
}

export default Stories
